import { useEffect } from 'react'

declare global {
  interface Window {
    interfaceBetweenSocarAppAndSocarWeb: any
  }
}

export const useInterfaceBetweenApp = () => {
  useEffect(() => {
    if (!window.interfaceBetweenSocarAppAndSocarWeb) {
      window.interfaceBetweenSocarAppAndSocarWeb = {
        onPause: () => {},
        onResume: () => {},
        updateVisitedHistory: () => {},
      }
    }
  }, [])

  const useSetOnPause = (onPause: () => void) => {
    useEffect(() => {
      if (!window.interfaceBetweenSocarAppAndSocarWeb) return

      window.interfaceBetweenSocarAppAndSocarWeb.onPause = onPause
    }, [onPause])
  }
  const useSetOnResume = (onResume: () => void) => {
    useEffect(() => {
      if (!window.interfaceBetweenSocarAppAndSocarWeb) return

      window.interfaceBetweenSocarAppAndSocarWeb.onResume = onResume
    }, [onResume])
  }

  const useSetUpdateVisitedHistory = (updateVisitedHistory: () => void) => {
    useEffect(() => {
      if (!window.interfaceBetweenSocarAppAndSocarWeb) return

      window.interfaceBetweenSocarAppAndSocarWeb.updateVisitedHistory =
        updateVisitedHistory
    }, [updateVisitedHistory])
  }

  return {
    useSetOnResume,
    useSetOnPause,
    useSetUpdateVisitedHistory,
  }
}
