import { PropsWithChildren, ReactNode } from 'react'

import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { ErrorBoundary } from 'react-error-boundary'

import { DefaultErrorFallback } from '.'

export const AsyncBoundary = ({ children }: PropsWithChildren) => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ErrorBoundary onReset={reset} fallbackRender={DefaultErrorFallback}>
      {children}
    </ErrorBoundary>
  )
}
