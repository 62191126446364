import type { CSSProperties } from 'react'

import styles from './styles.module.scss'

interface SkeletonProps {
  width: string | number
  height: string | number
  style?: CSSProperties
  className?: string
  borderRadius?: number
}

/**
 * @description Skeleton 컴포넌트는 로딩 중에 보여줄 아이템 요소입니다.
 * @param {string | number} width - Skeleton 컴포넌트의 너비를 결정합니다.
 * @param {string | number} height - Skeleton 컴포넌트의 높이를 결정합니다.
 * @param {CSSProperties} style - Skeleton 컴포넌트의 스타일을 결정합니다.
 * @param {string} className - Skeleton 컴포넌트의 클래스를 결정합니다.
 */
export const Skeleton = ({
  width,
  height,
  style = {},
  className = '',
  borderRadius = 8,
}: SkeletonProps) => {
  // could be perecentage or pixel or number
  const heightVal = typeof height === 'number' ? `${height}px` : height
  const widthVal = typeof width === 'number' ? `${width}px` : width

  return (
    <div
      style={{ ...style, height: heightVal, width: widthVal, borderRadius }}
      className={`${className} ${styles.loading}`}></div>
  )
}
