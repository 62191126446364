import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { RecoilRoot } from 'recoil'

import 'src/styles/global.css'
import 'src/utils/sprintf'
import { useInterfaceBetweenApp } from '@src/hooks/useInterfaceBetweenApp'
import { datadogNextjs } from 'configs/datadog-config'
import { DefaultHeader, DefaultScript } from 'src/components'

const OPEN_GRAPH = {
  type: 'website',
  title: 'title',
  description: 'description',
  url: 'https://www.url.ie/',
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
})

function MyApp({ Component, pageProps }: AppProps<any>) {
  const { initDatadog } = datadogNextjs()
  useInterfaceBetweenApp()
  initDatadog()

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <RecoilRoot>
            <DefaultHeader />
            <DefaultScript />
            <DefaultSeo openGraph={OPEN_GRAPH} />
            <Component {...pageProps} />
            <ReactQueryDevtools
              initialIsOpen={process.env.NODE_ENV === 'development'}
            />
          </RecoilRoot>
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
