import { tv } from 'tailwind-variants'

import { generateTwVariants } from '@src/utils/variants/generateVariants'

import { spacingVariant } from './spacingVariant'

export const flexVariant = tv({
  extend: spacingVariant,
  variants: {
    direction: {
      row: 'tw-flex-row',
      col: 'tw-flex-col',
      'row-reverse': 'tw-flex-row-reverse',
      'col-reverse': 'tw-flex-col-reverse',
    },
    items: {
      start: 'tw-items-start',
      center: 'tw-items-center',
      end: 'tw-items-end',
      baseline: 'tw-items-baseline',
      stretch: 'tw-items-stretch',
      initial: 'tw-items-initial',
      inherit: 'tw-items-inherit',
    },

    justify: {
      start: 'tw-justify-start',
      center: 'tw-justify-center',
      end: 'tw-justify-end',
      between: 'tw-justify-between',
      around: 'tw-justify-around',
      evenly: 'tw-justify-evenly',
      initial: 'tw-justify-initial',
      inherit: 'tw-justify-inherit',
    },
    flexType: {
      flex: 'tw-flex',
      'inline-flex': 'tw-inline-flex',
    },
    // ios 14에서 column direction에서 gap이 적용안되는 문제로 space로 대체
    'space-x': generateTwVariants('spacing', 'space-x'),
    'space-y': generateTwVariants('spacing', 'space-y'),
    'space-x-reverse': generateTwVariants('spacing', 'space-x-reverse'),
    'space-y-reverse': generateTwVariants('spacing', 'space-y-reverse'),
  },
})
