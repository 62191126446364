import {
  colors,
  spacing,
  fontSize,
  fontFamily,
  borderWidth,
  borderRadius,
} from './configs/tailwind-custom-config/tokens'

const config = {
  prefix: 'tw-',
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    colors,
    spacing,
    fontSize,
    fontFamily,
    borderWidth,
    borderRadius,
  },
  corePlugins: {
    preflight: false,
  },
  safelist: [
    // color
    ...Object.keys(colors).map((key) => `tw-text-${key}`),
    ...Object.keys(colors).map((key) => `tw-bg-${key}`),
    ...Object.keys(colors).map((key) => `tw-border-${key}`),
    // spacing
    ...Object.keys(spacing).map((key) => `tw-spacing-${key}`),
    ...Object.keys(spacing).map((key) => `tw-space-x-${key}`),
    ...Object.keys(spacing).map((key) => `tw-space-y-${key}`),
    // margin
    ...Object.keys(spacing).map((key) => `tw-m-${key}`),
    ...Object.keys(spacing).map((key) => `tw-mt-${key}`),
    ...Object.keys(spacing).map((key) => `tw-mb-${key}`),
    ...Object.keys(spacing).map((key) => `tw-ml-${key}`),
    ...Object.keys(spacing).map((key) => `tw-mr-${key}`),
    ...Object.keys(spacing).map((key) => `tw-mx-${key}`),
    ...Object.keys(spacing).map((key) => `tw-my-${key}`),
    // padding
    ...Object.keys(spacing).map((key) => `tw-p-${key}`),
    ...Object.keys(spacing).map((key) => `tw-pt-${key}`),
    ...Object.keys(spacing).map((key) => `tw-pb-${key}`),
    ...Object.keys(spacing).map((key) => `tw-pl-${key}`),
    ...Object.keys(spacing).map((key) => `tw-pr-${key}`),
    ...Object.keys(spacing).map((key) => `tw-px-${key}`),
    ...Object.keys(spacing).map((key) => `tw-py-${key}`),
  ],
}

export type TailwindConfig = typeof config

export type KeyOfTheme<T extends keyof TailwindConfig['theme']> =
  keyof TailwindConfig['theme'][T]

export type ValueOfTheme<T extends keyof TailwindConfig['theme']> =
  TailwindConfig['theme'][T][KeyOfTheme<T>]

export default config
