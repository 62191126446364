import lokalise from '@src/libs/locales/ko/ko.json'
import { GuideSectionProps } from '@src/views/Guide/GuideSection'

export const GUIDE_CONTENT_BIKE = {
  header: lokalise.elecle_guide_unlock_title,
  subtitle: lokalise.elecle_guide_unlock_subtitle_bike,
  body: lokalise.elecle_guide_unlock_body_bike,
  guideImage: {
    path: '/guide/elecle_guide_bike@3x.png',
    alt: '쏘카일레클 자전거 이미지',
  },
}

export const GUIDE_CONTENTS: GuideSectionProps[] = [
  {
    subtitle: lokalise.elecle_guide_unlock_subtitle_app,
    body: lokalise.elecle_guide_unlock_body_app,
    guideImage: {
      path: '/guide/elecle_guide_unlock@3x.png',
      alt: '쏘카일레클 자전거 이미지',
    },
  },
  {
    header: lokalise.elecle_guide_temp_lock_title,
    body: lokalise.elecle_guide_temp_lock_body,
    guideImage: {
      path: '/guide/elecle_guide_temp_lock@3x.png',
      alt: '쏘카일레클 자전거 이미지',
    },
  },
  {
    header: lokalise.elecle_guide_return_title,
    body: lokalise.elecle_guide_return_body1,
    guideImage: {
      path: '/guide/elecle_guide_return_gif.gif',
      alt: '쏘카일레클 자전거 이미지',
    },
  },
  {
    body: lokalise.elecle_guide_return_body2,
    guideImage: {
      path: '/guide/elecle_guide_return@3x.png',
      alt: '쏘카일레클 자전거 이미지',
    },
  },
]

export const GUIDE_NOTICE = {
  header: lokalise.elecle_guide_caution_title,
  body: [
    lokalise.elecle_guide_caution_body1,
    lokalise.elecle_guide_caution_body2,
    lokalise.elecle_guide_caution_body3,
  ],
}
