export const spacing = {
  0: '0px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  10: '10px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  22: '22px',
  24: '24px',
  26: '26px',
  28: '28px',
  30: '30px',
  32: '32px',
  // 임의 추가
  36: '36px',
  40: '40px',
  42: '42px',
  44: '44px',
  48: '48px',
  52: '52px',
  56: '56px',
  60: '60px',
  64: '64px',
  72: '72px',
  80: '80px',
  96: '96px',
  '40%': '40%',
  half: '50%',
  full: '100%',
}
