import { tv } from 'tailwind-variants'

export const dividerVariant = tv({
  variants: {
    color: {
      grey025: 'tw-border-grey025',
      grey030: 'tw-border-grey030',
    },
    size: {
      thin: 'tw-border-0 tw-border-t-1',
      dotted: 'tw-border-0 tw-border-t-1',
    },
    borderStyle: {
      solid: 'tw-border-solid',
      dotted: 'tw-border-dotted',
    },
  },
})
