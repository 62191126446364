import { dividerVariant } from './dividerVariant'

interface DividerProps {
  className?: string
  color?: 'grey030' | 'grey025'
  size?: 'thin'
  borderStyle?: 'solid' | 'dotted'
}

export const Divider = ({
  className = '',
  color = 'grey030',
  size = 'thin',
  borderStyle = 'solid',
}: DividerProps) => {
  return (
    <div
      className={dividerVariant({
        color,
        size,
        borderStyle,
        class: className,
      })}></div>
  )
}
