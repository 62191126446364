import { vsprintf } from 'sprintf-js'

declare global {
  interface String {
    sprintf(...args: any): string
  }
}

/* eslint-disable */
String.prototype.sprintf = function (): string {
  const args = Array.prototype.slice.call(arguments)
  return vsprintf(this.valueOf(), args)
}
