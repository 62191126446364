import { Button } from '@socar-inc/frontend-design-system'
import { IButtonProps } from '@socar-inc/frontend-design-system/dist/components/Button'

interface ButtonFloatingProps extends Partial<IButtonProps> {
  text: string
  disabled?: boolean
  onClickCapture?: () => void
  onClick?: () => void
  bgColor?: 'white' | 'transparent'
}

/**
 * @description 페이지 하단의 floating 효과의 버튼입니다.
 * regularFill 타입, blue 색상, bgColor를 transparent로 default로 가지고 있습니다.
 * @example
 * <ButtonFloating text="자전거 타러 가기" onClick={handleClick} bgColor="white"/>
 *
 */
export const ButtonFloating = ({
  text,
  disabled,
  bgColor = 'transparent',
  type = 'largeFill',
  color = 'blue',
  onClick,
  onClickCapture,
  style,
  className,
}: ButtonFloatingProps) => {
  const colorVariants = {
    white: 'tw-bg-gradient-to-b tw-from-white_rgb/0 tw-to-white_rgb tw-to-25%',
    transparent: '',
  }

  return (
    <section
      className={`tw-p-24 tw-pb-16 tw-fixed tw-bottom-0 tw-w-full ${colorVariants[bgColor]}`}>
      <button
        className={`design-system-button 
        ${className} ${type}-button-${color} 
        ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
        onClick={onClick}
        onClickCapture={onClickCapture}
        style={style}>
        {text}
      </button>
    </section>
  )
}
