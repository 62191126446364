export const fontSize = {
  10: ['10px', { lineHeight: '1.25' }],
  12: ['12px', { lineHeight: '1.5' }],
  14: ['14px', { lineHeight: '1.43' }],
  16: ['16px', { lineHeight: '1.5' }],
  18: ['18px', { lineHeight: '1.33' }],
  20: ['20px', { lineHeight: '28px' }],
  22: ['22px', { lineHeight: '32px' }],
  24: ['24px', { lineHeight: '1.33' }],
  28: ['28px', { lineHeight: '1.29' }],
  42: ['42px', { lineHeight: '0.86' }],
}
