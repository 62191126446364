import { AnchorHTMLAttributes, MouseEventHandler } from 'react'

import { Icon } from '@socar-inc/frontend-design-system'
import {
  IconColor,
  IconName,
} from '@socar-inc/frontend-design-system/dist/components/Icon/type'

import { flexVariant } from '@src/constants'

interface LinkTextProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  onClick?: MouseEventHandler<HTMLAnchorElement>
  text?: string
  className?: string
  iconFront?: IconName
  iconRear?: IconName
  color?: IconColor
  flexType?: 'flex' | 'inline-flex'
  textName?: string
}

export const LinkText = ({
  href,
  onClick,
  text,
  className,
  iconFront,
  iconRear,
  color = 'grey050',
  flexType = 'flex',
  textName = 'body2',
  ...args
}: LinkTextProps) => {
  return (
    <a
      href={href}
      {...args}
      className={`tw-text-grey050 tw-text=${color} ${className} ${flexVariant({
        flexType,
        justify: 'center',
      })}`}
      onClick={onClick}>
      {iconFront ? <Icon icon={iconFront} color={color} /> : null}
      <span className={textName}>{text}</span>
      {iconRear ? <Icon icon={iconRear} color={color} /> : null}
    </a>
  )
}
