export const fontFamily = {
  web: ['Spoqa Han Sans Neo'],
  android: ['Spoqa Han Sans Neo'],
  ios: [
    'Apple SD Gothic Neo',
    '-apple-system',
    'BlinkMacSystemFont',
    'Malgun Gothic',
    '맑은 고딕',
    'helvetica',
    'sans-serif',
  ],
}
