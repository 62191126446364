import { TabProps } from '@src/types'

/**
 * @description Tab 컴포넌트
 * @param {TabProps} props - options: 탭 옵션, selected: 선택된 탭, onChange: 탭 클릭 시 실행 함수
 * @example
 * <Tab
 *  options={[{label: '대여정보', value: 'rental'}, {label: '결제정보', value: 'payment'}]}
 *  selected={selected}
 *  onChange={setSelected}
 * />
 *
 */
export const Tab = ({ options, selected, onChange }: TabProps) => {
  const textStatusStyles = {
    selected: 'tw-text-primary tw-border-grey060',
    unselected: 'tw-text-grey040 tw-border-grey060/0',
  }

  return (
    <div className="tw-inline-flex tw-flex-row tw-justify-between tw-gap-6">
      {options.map(({ value, label }) => (
        <div
          key={value}
          className={`tw-flex tw-flex-1 tw-px-6 tw-pt-14 tw-pb-[13px] btw-justify-center tw-items-center tw-py-2 tw-cursor-pointer tw-border-0 tw-border-b-1 tw-border-solid ${
            selected === value
              ? textStatusStyles.selected
              : textStatusStyles.unselected
          } `}
          onClick={() => onChange(value)}>
          <div className="subtitle2">{label}</div>
        </div>
      ))}
    </div>
  )
}
